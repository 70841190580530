import React, { Suspense } from 'react';

import { Loader } from '@core/components/Loader/Loader';

type ComponentWithProps<P> = React.FC<P>;

export const Suspendable =
  <P extends IProvider | Record<string, unknown>>(
    Component: ComponentWithProps<P>
  ) =>
  (props: P) => (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );
