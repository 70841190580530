import { lazy, StrictMode, useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { Suspendable } from '@core/components/Suspendable';
import { ENV } from '@core/constants';
import Hotjar from '@hotjar/browser';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';

import './index.css';

const cashierVersion =
  new URL(location.href).searchParams.get('version') ??
  (window.globalENV.VITE_CASHIER_VERSION || 'old');
const mode = ENV.VITE_ENVIRONMENT === 'prod' ? 'production' : 'development';
const isSentryEnabled =
  window.globalENV.VITE_ENVIRONMENT === 'prod' ||
  window.globalENV.VITE_ENVIRONMENT === 'test';
const siteId = ENV.VITE_HOTJAR_SITE_ID;
const hotjarVersion = ENV.VITE_HOTJAR_VERSION;

const OldUI = Suspendable(lazy(() => import('./v1')));
const NewUI = Suspendable(lazy(() => import('./v2')));

if (ENV.VITE_ENVIRONMENT === 'prod') {
  Hotjar.init(siteId, hotjarVersion, {
    nonce: 'rAnDoM',
  });
}
if (isSentryEnabled) {
  Sentry.init({
    environment: mode,
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!

    // Performance Monitoring
    enabled: isSentryEnabled,
    // Set tracesSampleRate to 1.0 to capture 100%
    dsn: ENV.VITE_SENTRY_DSN,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [ENV.VITE_CASHIER_API_URL],
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      /webkit-masked-url:\//i,
    ],
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        matchRoutes,
        useNavigationType,
        createRoutesFromChildren,
      }),
    ],
    ignoreErrors: [
      // Hotjar errors
      "null is not an object (evaluating 'window.localStorage.getItem')",
      "null is not an object (evaluating 'window.localStorage.removeItem')",
      'Member PerformanceObserverInit.entryTypes is required and must be an instance of sequence',
      // '$ is not defined' - maybe related to praxis,
      // Browser extensions errors
      'Cannot redefine property: googletag',
      "Cannot read properties of undefined (reading 'site_id')",
      "null is not an object (evaluating 'iframe.contentWindow.postMessage')",
      // others
      'Object captured as promise rejection with keys: error, isUnhandledError, meta',
    ],
  });
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>{cashierVersion === 'old' ? <OldUI /> : <NewUI />}</StrictMode>
);
