if (window.hasOwnProperty('globalENV')) {
  window.globalENV = { ...window.globalENV };
} else {
  window.globalENV = {
    VITE_HOTJAR_SITE_ID: import.meta.env.VITE_HOTJAR_SITE_ID,
    VITE_CASHIER_API_URL: import.meta.env.VITE_CASHIER_API_URL,
    VITE_HOTJAR_VERSION: import.meta.env.VITE_HOTJAR_VERSION,
    VITE_CASHIER_WEB_URL: import.meta.env.VITE_CASHIER_WEB_URL,
    VITE_CASHIER_DOMAIN: import.meta.env.VITE_CASHIER_DOMAIN,
    VITE_ENVIRONMENT: import.meta.env.VITE_ENVIRONMENT,
    VITE_SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
    VITE_PAYMENT_IQ_URL: import.meta.env.VITE_PAYMENT_IQ_URL,
    VITE_SENTRY_AUTH_TOKEN: import.meta.env.VITE_SENTRY_AUTH_TOKEN,
    VITE_SC_AP_DEBUG_MODE: import.meta.env.VITE_SC_AP_DEBUG_MODE,
    VITE_PCI_TEST_ENABLED: import.meta.env.VITE_PCI_TEST_ENABLED,
    VITE_CITIZEN_URL: import.meta.env.VITE_CITIZEN_URL,
    VITE_MIFINITY_URL: import.meta.env.VITE_MIFINITY_URL,
    VITE_CASHIER_VERSION: import.meta.env.VITE_CASHIER_VERSION,
  };
}
