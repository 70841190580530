export const ENV = {
  VITE_SENTRY_DSN: window.globalENV?.VITE_SENTRY_DSN,
  VITE_ENVIRONMENT: window.globalENV?.VITE_ENVIRONMENT,
  VITE_CASHIER_DOMAIN: window.globalENV?.VITE_CASHIER_DOMAIN,
  VITE_HOTJAR_VERSION: window.globalENV?.VITE_HOTJAR_VERSION,
  VITE_HOTJAR_SITE_ID: window.globalENV?.VITE_HOTJAR_SITE_ID,
  VITE_CASHIER_API_URL: window.globalENV?.VITE_CASHIER_API_URL,
  VITE_SC_AP_DEBUG_MODE: window.globalENV?.VITE_SC_AP_DEBUG_MODE,
  VITE_SENTRY_AUTH_TOKEN: window.globalENV?.VITE_SENTRY_AUTH_TOKEN,
  VITE_PCI_TEST_ENABLED: window.globalENV?.VITE_PCI_TEST_ENABLED ?? false,
};
