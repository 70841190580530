export const METHODS = Object.freeze({
  PIX: 'PIX',
  PCI: 'PCI',
  UPI: 'UPI',
  CARD: 'CARD',
  SEPA: 'SEPA',
  RAPID: 'RAPID',
  MB_WAY: 'MB_WAY',
  BOLETO: 'BOLETO',
  PAYCASH: 'PAYCASH',
  CARD_V2: 'CARD_V2',
  INTERAC: 'INTERAC',
  BANKING: 'BANKING',
  GIROPAY: 'GIROPAY',
  JCB_S2S: 'JCB_S2S',
  WEBPAYZ: 'WEBPAYZ',
  APPLEPAY: 'APPLEPAY',
  EVOUCHER: 'EVOUCHER',
  POSTBANK: 'POSTBANK',
  VISA_S2S: 'VISA_S2S',
  UATP_S2S: 'UATP_S2S',
  GOOGLEPAY: 'GOOGLEPAY',
  NORISBANK: 'NORISBANK',
  SPARKASSE: 'SPARKASSE',
  MULTIBANCO: 'MULTIBANCO',
  COMMERZBANK: 'COMMERZBANK',
  HYPOVEREINS: 'HYPOVEREINS',
  MAESTRO_S2S: 'MAESTRO_S2S',
  DANKORT_S2S: 'DANKORT_S2S',
  NET_BANKING: 'NET_BANKING',
  OPEN_BANKING: 'OPEN_BANKING',
  DISCOVER_S2S: 'DISCOVER_S2S',
  DEUTSCHE_BANK: 'DEUTSCHE_BANK',
  MASTERCARD_S2S: 'MASTERCARD_S2S',
  OPEN_BANKING_V2: 'OPEN_BANKING_V2',
  WALLET_MIFINITY: 'WALLET_MIFINITY',
  WALLET_NETELLER: 'WALLET_NETELLER',
  DINERS_CLUB_S2S: 'DINERS_CLUB_S2S',
  INTERPAYMENT_S2S: 'INTERPAYMENT_S2S',
  VISA_ELECTRON_S2S: 'VISA_ELECTRON_S2S',
  GATECHARGE_PAYWALL: 'GATECHARGE_PAYWALL',
  CHINA_UNIONPAY_S2S: 'CHINA_UNIONPAY_S2S',
  AMERICAN_EXPRESS_S2S: 'AMERICAN_EXPRESS_S2S',
  DINERS_CLUB_US_CA_S2S: 'DINERS_CLUB_US_CA_S2S',
  VOLKSBANKEN_RAIFFEISEN: 'VOLKSBANKEN_RAIFFEISEN',
  DINERS_CLUB_INTERNATIONAL_S2S: 'DINERS_CLUB_INTERNATIONAL_S2S',
});

export enum METHODS_ENUM {
  AMERICAN_EXPRESS_S2S = 'AMERICAN_EXPRESS_S2S',
  APPLEPAY = 'APPLEPAY',
  BANKING = 'BANKING',
  BOLETO = 'BOLETO',
  CARD = 'CARD',
  CARD_V2 = 'CARD_V2',
  CHINA_UNIONPAY_S2S = 'CHINA_UNIONPAY_S2S',
  COMMERZBANK = 'COMMERZBANK',
  DANKORT_S2S = 'DANKORT_S2S',
  DEUTSCHE_BANK = 'DEUTSCHE_BANK',
  DINERS_CLUB_INTERNATIONAL_S2S = 'DINERS_CLUB_INTERNATIONAL_S2S',
  DINERS_CLUB_S2S = 'DINERS_CLUB_S2S',
  DINERS_CLUB_US_CA_S2S = 'DINERS_CLUB_US_CA_S2S',
  DISCOVER_S2S = 'DISCOVER_S2S',
  EVOUCHER = 'EVOUCHER',
  GATECHARGE_PAYWALL = 'GATECHARGE_PAYWALL',
  GIROPAY = 'GIROPAY',
  GOOGLEPAY = 'GOOGLEPAY',
  HYPOVEREINS = 'HYPOVEREINS',
  INTERAC = 'INTERAC',
  INTERPAYMENT_S2S = 'INTERPAYMENT_S2S',
  JCB_S2S = 'JCB_S2S',
  MAESTRO_S2S = 'MAESTRO_S2S',
  MASTERCARD_S2S = 'MASTERCARD_S2S',
  MB_WAY = 'MB_WAY',
  MULTIBANCO = 'MULTIBANCO',
  NET_BANKING = 'NET_BANKING',
  NORISBANK = 'NORISBANK',
  OPEN_BANKING = 'OPEN_BANKING',
  OPEN_BANKING_V2 = 'OPEN_BANKING_V2',
  PAYCASH = 'PAYCASH',
  PCI = 'PCI',
  PIX = 'PIX',
  POSTBANK = 'POSTBANK',
  RAPID = 'RAPID',
  SEPA = 'SEPA',
  SPARKASSE = 'SPARKASSE',
  UATP_S2S = 'UATP_S2S',
  UPI = 'UPI',
  VISA_ELECTRON_S2S = 'VISA_ELECTRON_S2S',
  VISA_S2S = 'VISA_S2S',
  VOLKSBANKEN_RAIFFEISEN = 'VOLKSBANKEN_RAIFFEISEN',
  WALLET_MIFINITY = 'WALLET_MIFINITY',
  WALLET_NETELLER = 'WALLET_NETELLER',
  WEBPAYZ = 'WEBPAYZ',
}

export type METHOD_NAME = `${METHODS_ENUM}`;
